<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after">
        审核商务信息
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-loading="loading">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="基本信息"></el-tab-pane>
        <el-tab-pane label="附件"></el-tab-pane>
      </el-tabs>

      <div class="fullScreenMainCon">
        <Manage
          width="600px"
          @showEditDialog="showEditDialog = true"
          :BusinessId="form.id"
          v-show="activeName == 0"
        ></Manage>
        <Annex :manageId="form.id" :file_Data="file_Data" v-show="activeName == 1"></Annex>
      </div>
      <div class="fullScreenOperation">
        <!-- <el-button

                        type="success"
                        :loading="loading"
                        @click="Save()"
                >
                    创建项目
                </el-button> -->
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    Crumbs: () => import("@/components/Crumbs.vue"),
    Annex: () => import("./Annex.vue"),
    Manage: () => import("@/views/publicView/Manage.vue"),
    Dictionary: () => import("@/components/Dictionary.vue"),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function() {
        return {};
      },
    },
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      activeName: "0",
      rules: {},
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: "portrait",
      file_Data: [],
    };
  },
  inject: ["re"],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function(newVal) {
        // this.showEditDialog = newVal;
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function(newVal, oldVal) {
        this.init();
      },
    },
  },
  methods: {
    Save() {
      this.$refs.form.validate((valid, obj) => {
        this.form.status += 1;

        if (valid) {
          let obj = this.form;
          this.editDialogLoading = true;

          this.$api.businessManage
            .updateBusinessManageStatus(obj)
            .then((res) => {
              this.editDialogLoading = false;
              this.showEditDialog = false;
              this.$message.success("操作成功！");
              this.re();
            })
            .catch((err) => {
              this.editDialogLoading = false;
              console.log(err);
            });
        } else {
          this.scrollView(obj);
        }
      });
    },
    init() {
      this.editDialogLoading = true;
      if (this.options.id) {
        this.$api.businessManage
          .getBusinessManage(this.options.id)
          .then((res) => {
            this.editDialogLoading = false;

            let arr = [];
            if (res.data.businessFileList.length > 0) {
              for (let i = 0; i < res.data.businessFileList.length; i++) {
                const e = res.data.businessFileList[i];
                e.attachmentName = e.fileName;
                e.attachmentPath = e.filePath;
                arr.push(e);
              }
            }
            this.file_Data = arr;
            res.data.businessManage.list_s = this.options.list_s;
            res.data.businessManage.role_type = this.options.role_type;
            this.form = res.data.businessManage;
          })
          .catch((err) => {
            this.editDialogLoading = false;
            console.log(err);
          });
      } else {
        this.file_Data = [];
      }
    },
  },
};
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import "@/styles/config.scss";
</style>
